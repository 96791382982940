import { forwardRef } from "react";
import { Form, Input } from "rsuite";

const Textarea = forwardRef((props: any, ref: any) => (
  <Input {...props} ref={ref} as="textarea" />
));
Textarea.displayName = "Textarea";

// export default function TextAreaField(props:any) {
//   const { name, label, ...rest } = props;
//   return (
//     <Form.Group controlId={`${name}`}>
//       <Form.ControlLabel>{label}</Form.ControlLabel>
//       <Form.Control rows={2} style={{width:"100%"}} name={name} accepter={Textarea} {...rest}/>
//     </Form.Group>
//   );
// }

export default function TextAreaField(props: any) {
  const {
    name,
    label,
    accepter,
    hideLabel,
    formGroupStyle,
    formGroupClass,
    ...rest
  } = props;
  return (
    <Form.Group
      controlId={`${name}`}
      style={formGroupStyle}
      className={formGroupClass}
    >
      {hideLabel ? null : (
        <Form.ControlLabel
          className="label-2 mr-2 mb-0 md:!mb-1 min-w-[72px]"
          style={{ marginTop: 7 }}
        >
          {label}
        </Form.ControlLabel>
      )}
      {/* <Form.Control name={name} accepter={accepter} {...rest} /> */}
      <Form.Control
        rows={4}
        style={{ width: "100%" }}
        name={name}
        accepter={Textarea}
        {...rest}
      />
    </Form.Group>
  );
}
