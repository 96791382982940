import { API_URL, API_KEY } from "../config";

export const handleVenueImages = async (venueId: number) => {
  if (!venueId) return;
  // get venue images
  const response = await fetch(`${API_URL}/venue_images/venue/${venueId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  const images = response.json();
  return images;
};

export const handleVenueDetails = async (venueId: number) => {
  if (!venueId) return;
  let response = await fetch(`${API_URL}/venues/${venueId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  const venue = response.json();
  return venue;
};

export const handleVenueDetailsBySlug = async (venueSlug: string) => {
  if (!venueSlug) return;
  let response = await fetch(`${API_URL}/venues/slug/${venueSlug}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  if (response.status === 200) {
    const venue = response.json();
    return venue;
  } else {
    return null
  }
};

export const handleVenueSearchSimple = async (searchQuery: string) => {

  let response = await fetch(`${API_URL}/search/simple/`, {
    method: "post",
    body: JSON.stringify({ data: searchQuery }),
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  if (response.status === 200) {
    const venue = response.json();
    return venue;
  } else {
    return null
  }
};

export const handleSampleQueries = async () => {
  let response = await fetch(`${API_URL}/samples/`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  const venue = response.json();
  return venue;
};

export const handleLike = async (like: boolean, searchQuery: string) => {

  let response = await fetch(`${API_URL}/${like ? "like" : "dislike"}/`, {
    method: "post",
    body: JSON.stringify({ query: searchQuery }),
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  if (response.status === 200) {
    const venue = response.json();
    return venue;
  } else {
    return null
  }
};