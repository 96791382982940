import { Modal, Form, Schema, ButtonToolbar, Button } from "rsuite";
import TextField from "../../../components/form/textfield";
import { useRef, useState, useCallback } from "react";
import TextAreaField from "../../../components/form/textareafield";
import { contactApi } from "../../../api/feedback";
import MediaQuery from "react-responsive";
interface Props {
  open: boolean;
  handleToggle: (state: boolean) => void;
  handleWasSuccess: () => void;
  venueId: string;
  venueName: string;
}
const { StringType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired(),
  email: StringType().isRequired().isEmail(),
  description: StringType(),
});

export const ContactFormModal = ({
  open,
  handleToggle,
  venueId,
  venueName,
  handleWasSuccess,
}: Props) => {
  const formRef = useRef<any>();
  const [formError, setFormError] = useState<any>();
  const [formValue, setFormValue] = useState<any>({});
  const [formDefaultValue, setFormDefaultValue] = useState({});

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log(formError, "Form Error");
      return;
    }
    console.log(formValue);
    try {
      await contactApi({ ...formValue, venueId: venueId });
      handleWasSuccess();
      handleToggle(false);
    } catch (e) {
      console.log(e);
    }
  }, [formValue, venueId]);

  return (
    <Modal
      size={"sm"}
      className="custom-width-modal"
      open={open}
      onClose={() => handleToggle(false)}
    >
      <div style={{ paddingInline: 6 }}>
        <Modal.Header className="mt-4">
          <Modal.Title style={{ fontWeight: 500 }}>
            Reaching out about “{venueName}”
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            autoComplete="off"
            ref={formRef}
            checkTrigger={"none"}
            formDefaultValue={formDefaultValue}
            onChange={setFormValue}
            onCheck={setFormError}
            formError={formError}
            model={model}
            onSubmit={handleSubmit}
          >
            <MediaQuery minWidth={768}>
              <div className="mb-6">
                <TextField
                  name="name"
                  label="Your Name"
                  placeholder="Name"
                  formGroupStyle={{ display: "flex" }}
                  className="!w-full"
                  size={"sm"}
                />
              </div>
              <div className="mb-6">
                <TextField
                  name="email"
                  placeholder="Email"
                  label="Your Email"
                  formGroupStyle={{ display: "flex" }}
                  className="!w-full"
                  size={"sm"}
                />
              </div>
              <div className="mb-6">
                <TextAreaField
                  name="description"
                  Placeholder="Description"
                  label="Description"
                  formGroupStyle={{ display: "flex" }}
                  className="!w-full"
                  size={"sm"}
                />
              </div>
            </MediaQuery>

            <MediaQuery maxWidth={768}>
              <div className="mb-6">
                <TextField
                  name="name"
                  placeholder="Name"
                  label="Your Name"
                  className="!w-full"
                  size={"sm"}
                />
              </div>

              <div className="mb-6">
                <TextField
                  name="email"
                  placeholder="Email"
                  label="Your Email"
                  className="!w-full"
                  size={"sm"}
                />
              </div>

              <div className="mb-6">
                <TextAreaField
                  name="description"
                  Placeholder="Description"
                  label="Description"
                  className="!w-full"
                  size={"sm"}
                />
              </div>
            </MediaQuery>

            <ButtonToolbar className="flex flex-col gap-5 md:flex-row md:ml-[80px]">
              <Button
                appearance="primary"
                onClick={handleSubmit}
                className="button-text w-full md:w-fit md:!py-3 md:!px-4"
              >
                ASK BAROJ EVENTS
              </Button>

              <Button
                appearance="primary"
                className="button-text button-cancel w-full md:w-fit md:!py-3 md:!px-4"
                onClick={() => handleToggle(false)}
              >
                CANCEL
              </Button>
            </ButtonToolbar>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};
