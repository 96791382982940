import { Swiper, SwiperSlide } from "swiper/react";
import { getPrimaryColor } from "../../../utils/helpers";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface propsInterface {
  images: {
    ImageID: number;
    ImageURL: string;
    VenueID: number;
    PrimaryColors: string;
  }[];
  venueSlug: string;
}

export const ImageSlider = ({ images, venueSlug }: propsInterface) => {
  return (
    <a href={`/venue/${venueSlug}`} target="_blank" rel="noopener noreferrer">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop
        navigation={true}
        className="venue-list-swiper"
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        style={{
          aspectRatio: 274 / 194,
          backgroundColor: "var(--rs-gray-300)",
        }}
      >
        {images?.map((image) => {
          return (
            <SwiperSlide
              key={image.ImageID}
              style={{
                backgroundColor: getPrimaryColor(image.PrimaryColors),
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  aspectRatio: 274 / 194,
                  objectFit: "cover",
                  width: "100%",
                }}
                src={image.ImageURL}
                alt=""
                loading="lazy"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </a>
  );
};
