export const CardIcon = ({ icon, title, className, ...others }: any) => {
  return (
    <div
      {...others}
      className={`flex flex-wrap min-w-fit items-center border-[1px] border-[--rs-primary-100] rounded-[6px] px-2 pr-3 md:pr-8 py-[9px] shadow-xss ${className}`}
    >
      <div className="border-[.5px] border-[--rs-primary-200] rounded-[6px] bg-[--rs-primary-50] w-8 h-8 flex justify-center items-center mr-[9px]">
        <img src={icon} alt={title} loading="lazy" className="h-4" />
      </div>
      <span className="subtitle-2">{title}</span>
    </div>
  );
};
