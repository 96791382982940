import "./App.css";
import "rsuite/dist/rsuite.min.css";
import "./assets/css/rsuite-colors.css";
import { CustomProvider } from "rsuite";
import { RouteHandler } from "./components/route-handler";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ScrollToTop } from "./components/actions/scroll-to-top";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryCLient = new QueryClient();

function App() {
  return (
    <CustomProvider theme="light">
      <QueryClientProvider client={queryCLient}>
        <RouteHandler />
        <ScrollToTop />
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </CustomProvider>
  );
}

export default App;
