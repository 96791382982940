export const getPrimaryColor = (primaryColors: string) => {
  try {
    return primaryColors.split(",")[0];
  } catch {
    return "var(--rs-gray-300)";
  }
};

export const prettifyUr = (url: string) => {
  var prettyUrl = url.replace(/^(https?:\/\/)?(www\.)?|\/$/g, '');
  return prettyUrl;
};