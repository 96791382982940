import BarojLogo from "../../assets/images/baroj-logo.svg";
import MailIcon from "../../assets/images/mail.svg";
import InstagramIcon from "../../assets/images/instagram-footer.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="bg-[--rs-gray-900] text-[--rs-gray-50] label-2 py-7 px-[6.25%]">
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <a href="https://www.barojevents.com/" target="_blank" rel="noreferrer">
          <img
            src={BarojLogo}
            alt="baroj logo"
            loading="lazy"
            className="mb-6 md:mb-0 max-h-[34px] md:max-h-[50px]"
          />
        </a>
        <div className="flex h-[50px]">
          <div
            className="flex flex-col pl-[10px] ml-0 md:ml-[28px] py-1 justify-between"
            style={{ borderLeft: "1px solid #fff" }}
          >
            <a
              href="https://www.barojevents.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>
            <a
              href="https://www.barojevents.com/blog"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
          </div>
          <div
            className="flex flex-col pl-[10px] ml-[48px] py-1 justify-between"
            style={{ borderLeft: "1px solid #fff" }}
          >
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
          </div>
          <div className="flex flex-col pl-10 py-[2px] justify-between ">
            <a href="mailto:hello@barojevents.com">
              <img src={MailIcon} alt="mail-icon" loading="lazy" />
            </a>
            <a
              href="https://www.instagram.com/baroj.events/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={InstagramIcon} alt="instagram-icon" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
      <p className="label-3 mb-0 mt-8">
        ©2023 by Baroj Events. All rights reserved. | VenueVow and all Baroj
        Events variants, and the logos, are trademarks of Baroj Events LLC,
        registered or pending in the USPTO and the state of Illinois.
      </p>
    </div>
  );
};
