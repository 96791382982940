import { Button, Col, FlexboxGrid } from "rsuite";
import { Plus } from "@rsuite/icons";
// import { ImageSlider } from "./image-slider";

interface propsInterface {
  noDescription?: boolean;
  fullSize?: boolean;
}
export const ResultCardSkeleton = ({
  noDescription,
  fullSize,
}: propsInterface) => {
  return (
    <FlexboxGrid.Item
      as={Col}
      xs={24}
      sm={fullSize ? 24 : 12}
      md={fullSize ? 24 : 8}
      lg={fullSize ? 24 : 6}
      className="px-1 pb-4"
    >
      <div className="rounded-[12px] bg-[var(--rs-gray-100)] overflow-hidden border-[1px] border-[--rs-gray-300] h-full">
        <div
          className="venue-list-swiper shining"
          style={{
            aspectRatio: 274 / 194,
            backgroundColor: "var(--rs-gray-300)",
          }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
          ></div>
        </div>

        <div className="p-4">
          <div className="mb-2 title-2 rounded-[4px] shining w-[50%]">
            <span className="invisible">.</span>
          </div>

          <address className="subtitle-2 mb-[22px] rounded-[4px] shining w-[65%]">
            <span className="invisible">.</span>
          </address>
          {noDescription ? null : (
            <p className="body-2 mb-[18px]" style={{ minHeight: 32.8 }}>
              <div className="mb-1 rounded-[4px] shining w-[100%]">
                <span className="invisible">.</span>
              </div>
              <div className="mb-1 rounded-[4px] shining w-[90%]">
                <span className="invisible">.</span>
              </div>
            </p>
          )}
          <div className="flex justify-end">
            <span className="shining rounded-[4px]">
              <Button
                appearance="ghost"
                className="button-text invisible"
                style={{
                  padding: "10px 24px 10px 16px",
                  boxShadow: "none",
                }}
              >
                <Plus style={{ fontSize: 12, marginRight: 10 }} /> MORE DETAILS
              </Button>
            </span>
          </div>
        </div>
      </div>
    </FlexboxGrid.Item>
  );
};
