import { Form } from "rsuite";

export default function TextField(props: any) {
  const {
    name,
    label,
    accepter,
    hideLabel,
    formGroupStyle,
    formGroupClass,
    ...rest
  } = props;
  return (
    <Form.Group
      controlId={`${name}`}
      style={formGroupStyle}
      className={formGroupClass}
    >
      {hideLabel ? null : (
        <Form.ControlLabel
          className="label-2 mr-2 mb-0 md:!mb-1 min-w-[72px]"
          style={{ marginTop: 7 }}
        >
          {label}
        </Form.ControlLabel>
      )}
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}
