import { Button, Col, FlexboxGrid } from "rsuite";
import { Plus } from "@rsuite/icons";
import { ImageSlider } from "./image-slider";
import { useNavigate } from "react-router-dom";

interface propsInterface {
  data: any;
  images: any[];
  noDescription?: boolean;
  fullSize?: boolean;
}
const ResultCard = ({
  data,
  images,
  noDescription,
  fullSize,
}: propsInterface) => {
  return (
    <FlexboxGrid.Item
      as={Col}
      xs={24}
      sm={fullSize ? 24 : 12}
      md={fullSize ? 24 : 8}
      lg={fullSize ? 24 : 6}
      className="px-1 pb-4"
    >
      <div className="rounded-[12px] bg-[var(--rs-gray-100)] overflow-hidden border-[1px] border-[--rs-gray-300] h-full">
        <ImageSlider images={images} venueSlug={data.Slug} />
        <div className="p-4">
          <div className="text-overflow title-2 text-[--rs-gray-900] mb-2">
            {data?.VenueName}
          </div>
          <address className="text-overflow subtitle-2 text-[#1B66F2] not-italic mb-[22px]">
            {data?.Location}
          </address>
          {noDescription ? null : (
            <p
              className="body-2 text-overflow text-overflow-2 mb-[22px] text-[--rs-gray-700]"
              style={{ minHeight: 32.8 }}
            >
              {data?.Description}
            </p>
          )}
          <div className="flex justify-end">
            <a
              href={`/venue/${data?.Slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                appearance="ghost"
                className="button-text"
                style={{
                  padding: "10px 24px 10px 16px",
                  boxShadow: "none",
                }}
              >
                <Plus style={{ fontSize: 12, marginRight: 10 }} /> MORE DETAILS
              </Button>
            </a>
          </div>
        </div>
      </div>
    </FlexboxGrid.Item>
  );
};

export default ResultCard;
