import { useEffect, useState } from "react";
import { Button } from "rsuite";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";

import "swiper/css";
import { handleSampleQueries } from "../../../api/venue";
import { Autoplay } from "swiper/modules";
import "swiper/css/autoplay";

const SampleQueries = () => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [step, setStep] = useState(0);

  const [samples, setSamples] = useState([]);
  const slideTo = (index: number) => {
    swiper?.slideTo(index);
    setStep(index);
  };

  useEffect(() => {
    (async () => {
      const res = await handleSampleQueries();
      setSamples(res.samples ?? []);
    })();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      slideTo(1);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [samples]);

  return samples.length ? (
    <div className="bg-[#FCF2FFD9] px-[22px] py-[18px] mt-3 rounded-[6px] w-full md:max-w-[75%] mx-auto">
      <p className="title font-bold mb-2 text-black">You may try:</p>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        spaceBetween={0}
        slidesPerView={1}
        loop
        onSwiper={setSwiper}
        onSlideChange={(s) => {
          setStep(isNaN(s.realIndex) ? 0 : s.realIndex);
        }}
      >
        {samples?.map((sample, index) => {
          return (
            <SwiperSlide key={index}>
              <p>“{sample}”</p>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex justify-between items-end mt-4">
        <div className="flex flex-row items-center mx-auto">
          {samples.map((sample, index) => (
            <div
              key={index}
              className="rounded-full w-[7px] h-[7px] bg-white inline-block mr-1 cursor-pointer shadow-md"
              style={{
                border: index === step ? "1px solid #BC6BCF" : "none",
              }}
              onClick={() => {
                slideTo(index);
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};
export default SampleQueries;
