import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, FlexboxGrid, Loader, Message } from "rsuite";
import ShareIcon from "../../assets/images/share.svg";
import { CardIcon } from "./components/card-icon";

import GlobeIcon from "../../assets/images/globe.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import LocationIcon from "../../assets/images/location.svg";
import WeddingWireIcon from "../../assets/images/wedding-wire.svg";
import TheKnotIcon from "../../assets/images/the-knot.svg";
import PepoleIcon from "../../assets/images/people.svg";
import { VenueImages } from "./components/venue-images";
import { ContactFormModal } from "./components/contact-form";
import { handleVenueDetailsBySlug, handleVenueImages } from "../../api/venue";
import { VenueModel } from "../../models/venue";
import { Footer } from "./../../components/footer/footer";

const Venue = () => {
  const { slug } = useParams();
  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [venue, setVenue] = useState<VenueModel>();
  const [images, setImages] = useState([]);
  const handleToggle = (state: boolean) => {
    setOpen(state);
  };

  const handleSharing = async (url: string, title: string) => {
    if (navigator.share) {
      try {
        await navigator.share({ url: url });
      } catch (error) {
        console.log(`Oops! Couldn't share the venue: ${error}`);
      }
    } else {
      console.log("Web share is currently not supported on this browser.");
    }
  };

  useEffect(() => {
    (async () => {
      setError(false);
      setLoading(true);
      if (!slug) return;
      try {
        const ven = await handleVenueDetailsBySlug(slug);
        if (!ven) return;
        const venueImages = await handleVenueImages(ven.Id);
        setVenue(ven);
        setImages(venueImages ?? []);
      } catch {
        setError(true);
      }
      setLoading(false);
    })();
  }, [slug]);

  const [wasSuccessful, setWasSuccessful] = useState(false);
  const handleWasSuccess = () => {
    setWasSuccessful(() => true);
    // setTimeout(() => {
    //   setWasSuccessful(() => false);
    // }, 10000);
  };
  return error ? (
    <>
      <div className="text-center h-[100svh] flex flex-col justify-between">
        <h1 className="mt-[20vh]">Cannot load venue</h1>
        <Footer />
      </div>
    </>
  ) : loading || !venue ? (
    <div className="w-[100vw] h-[100svh] flex items-center justify-center">
      <Loader size="md" />
    </div>
  ) : (
    <>
      <div className="max-w-[87.5vw] min-h-[73.3vh] mx-auto mt-12 mb-16">
        <div className="flex justify-between items-center mb-4">
          <h1 className="title-1">{venue.VenueName}</h1>
          <Button
            appearance="primary"
            size="md"
            className="button-text button-outlined-blue !px-2 md:!px-2 !py-2"
            onClick={() => {
              handleSharing(document.location.href, venue.VenueName);
            }}
          >
            <img
              src={ShareIcon}
              alt="share-icon"
              className="md:mr-2"
              loading="lazy"
            />
            <span className="hidden md:inline">SHARE VENUE</span>
          </Button>
        </div>

        <FlexboxGrid className="mb-4">
          <FlexboxGrid.Item as={Col} colspan={24} md={12}>
            <VenueImages images={images} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} md={12}>
            <div className="flex mb-7 gap-2 flex-wrap mt-4 md:mt-1">
              {venue.Location ? (
                <a
                  className="w-full"
                  href={`https://maps.google.com/?q=${venue.Location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardIcon
                    title={venue.Location}
                    icon={LocationIcon}
                    className="w-full"
                  />
                </a>
              ) : null}

              {venue.Website ? (
                <a
                  href={venue.Website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardIcon
                    title="Venue Website"
                    icon={GlobeIcon}
                    className="w-[calc(50%-4px)] md:w-[20%]"
                  />
                </a>
              ) : null}

              {venue.Instagram ? (
                <a
                  href={`https://instagram.com/${venue.Instagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardIcon
                    title="Instagram"
                    icon={InstagramIcon}
                    className="w-[calc(50%-4px)] md:w-[20%]"
                  />
                </a>
              ) : null}
            </div>
            <h2 className="title-1 mb-2.5">About</h2>
            <p className="body-1 text-[--rs-gray-700] mb-8">
              {venue.Description ?? "No Description"}
            </p>
            <div className="flex gap-2 flex-wrap mb-6 md:mb-0">
              <CardIcon
                title={`For ${venue.Capacity} guests`}
                icon={PepoleIcon}
                className="w-full md:w-[30%]"
              />

              {venue.WeddingWireURL ? (
                <a
                  href={venue.WeddingWireURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardIcon
                    title="Weddingwire"
                    icon={WeddingWireIcon}
                    className="w-[calc(50%-4px)] md:w-[30%]"
                  />
                </a>
              ) : null}

              {venue.TheKnotURL ? (
                <a
                  href={venue.TheKnotURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardIcon
                    title="The Knot"
                    icon={TheKnotIcon}
                    className="w-[calc(50%-4px)] md:w-[30%]"
                  />
                </a>
              ) : null}
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>

        {!wasSuccessful ? (
          <Button
            appearance="primary"
            onClick={() => handleToggle(true)}
            className="button-text w-full md:w-fit md:!py-3 md:!px-4"
          >
            ASK BAROJ FOR MORE INFO
          </Button>
        ) : null}
        {wasSuccessful ? (
          <Message
            showIcon
            type="success"
            className="mt-4 w-fit shadow-xss"
            onClick={() => setWasSuccessful(false)}
            style={{ fontWeight: 500, borderRadius: "6px" }}
          >
            Your request has been successfully submitted.
          </Message>
        ) : null}
      </div>
      <ContactFormModal
        open={open}
        handleToggle={handleToggle}
        handleWasSuccess={handleWasSuccess}
        venueId={venue.Id.toString()}
        venueName={venue.VenueName.toString()}
      />
      <Footer />
    </>
  );
};

export default Venue;
