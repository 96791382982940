import {
  Button,
  Divider,
  FlexboxGrid,
  IconButton,
  Input,
  InputGroup,
} from "rsuite";
import VenueVowLogo from "../../assets/images/venue-vow2.svg";
import ReloadIcon from "../../assets/images/reload.svg";
import LikeIcon from "../../assets/images/like.svg";
import ResultCard from "./components/result-card";
import VoiceIcon from "../../assets/images/voice.svg";
import SearchIcon from "../../assets/images/search-icon.svg";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useState, useEffect, useCallback, useRef } from "react";
import "swiper/css";
import oboe from "oboe";
import { ResultCardSkeleton } from "../../components/skeletons/result-card-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  API_URL,
  API_KEY,
  NUMBER_OF_RESULTS,
  MAX_INTERESTED_IN,
} from "../../config";
import {
  handleLike,
  handleVenueDetails,
  handleVenueImages,
  handleVenueSearchSimple,
} from "../../api/venue";
import { useSpeechRecognition } from "../../hooks/speech-recognition";
import { Footer } from "../../components/footer/footer";
import { IndeterminateLoader } from "../../components/loaders/indeterminate-loader";

import { Navigation } from "swiper/modules";
import "swiper/css/navigation";

const excludedDescs = [
  "exclude",
  // "Get Description using API.",
  // "AI Generated Description loading...",
];
interface ObjectType {
  [key: number]: boolean;
}
const SearchResults = () => {
  const speech = useSpeechRecognition();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get("s"));
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingRelevant, setLoadingRelevant] = useState(false);
  const [loaded, setLoaded] = useState<number[]>([]);
  const [rendered, setRendered] = useState<ObjectType>({});
  const [maxVenue, setMaxVenue] = useState(NUMBER_OF_RESULTS);
  const [relevantLoaded, setRelevantLoaded] = useState(0);
  const [relevantLength, setRelevantLength] = useState(0);
  const [error, setError] = useState();
  const [triggerSearch, setTriggerSearch] = useState(false);

  const [venues, setVenues] = useState<any>([]);
  const [venueImages, setVenueImages] = useState<any>([]);
  const [relevantVenues, setRelevantVenues] = useState<any>([]);
  const [relevantVenueImages, setRelevantVenueImages] = useState<any>([]);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [liked, setLiked] = useState<boolean | null>(null);
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    if (speech.transcript.length > 1000 || !speech.transcript.length) return;
    setSearchValue(speech.transcript);
    setSearchParams({ ["s"]: speech.transcript });
  }, [speech.transcript]);

  const handleChangeHeight = (element: any) => {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  };

  const handleSearch = (value: any) => {
    console.log(value);
    if (value.length > 1000) return;
    setSearchValue(value);
    setSearchParams({ ["s"]: value });
  };

  const handleKeyDown = function (event: any) {
    if (event.key === "Enter") {
      event.preventDefault();
      setTriggerSearch(!triggerSearch);
    }
    const element = event.target;
    handleChangeHeight(element);
  };

  const loadVenue = async (id: number, isInterested = false) => {
    const venue = await handleVenueDetails(id);
    const images = await handleVenueImages(id);
    if (!isInterested) {
      setVenues((data: any) => {
        return [...data, venue];
      });

      setVenueImages((data: any) => {
        return [...data, images];
      });

      setRendered((data) => {
        return { ...data, [id]: true };
      });
    } else {
      setRelevantVenues((data: any) => {
        return [...data, venue];
      });

      setRelevantVenueImages((data: any) => {
        return [...data, images];
      });
      setRelevantLoaded((data) => data + 1);
    }
  };

  useEffect(() => {
    if (!searchValue) return;
    let isCancelled = false;

    setLiked(null);
    setVenues([]);
    setVenueImages([]);
    setLoaded([]);
    setRendered({});
    setRelevantVenues([]);
    setRelevantVenueImages([]);
    setRelevantLoaded(0);
    setRelevantLength(0);
    setMaxVenue(NUMBER_OF_RESULTS);

    setLoading(true);
    setLoadingRelevant(true);
    try {
      (async () => {
        const res = await handleVenueSearchSimple(searchValue);
        if (!res) return;
        setLoaded(res.related_venue_IDs);
        setRelevantLength(
          Math.min(res.may_also_be_interested_in.length, MAX_INTERESTED_IN)
        );
        for (
          let i = 0;
          i < Math.min(res.related_venue_IDs.length, maxVenue);
          i++
        ) {
          if (isCancelled) {
            return;
          }
          await loadVenue(res.related_venue_IDs[i]);
        }
        setLoading(false);

        for (
          let i = 0;
          // i < res.may_also_be_interested_in.length;
          i < Math.min(res.may_also_be_interested_in.length, MAX_INTERESTED_IN);
          i++
        ) {
          if (isCancelled) {
            return;
          }
          console.log(i);
          await loadVenue(res.may_also_be_interested_in[i], true);
        }
        setLoadingRelevant(false);
      })();
    } catch (error) {
    } finally {
      //
    }
    return () => {
      // Cleanup function to set the flag when the component is unmounted
      isCancelled = true;
    };
  }, [triggerSearch]);

  const handleLoadMore = useCallback(async () => {
    let numberOfRendered = 0;
    setLoading(true);

    for (let id of [...loaded].splice(maxVenue)) {
      if (numberOfRendered < NUMBER_OF_RESULTS) {
        numberOfRendered++;
        await loadVenue(id);
      }
    }
    setLoading(false);
  }, [loaded, maxVenue]);

  const textareaRef = useRef(null);
  const handleHeight = (e: any) => {
    const element = e.target;
    handleChangeHeight(element);
  };

  useEffect(() => {
    const element: any = textareaRef.current;
    if (!element) return;
    handleChangeHeight(element);
  }, [textareaRef]);

  const handleLikeDislike = useCallback(
    async (like: boolean) => {
      if (!searchValue) return;
      if (like) {
        setLiked(true);
        await handleLike(true, searchValue);
      } else {
        setLiked(false);
        await handleLike(false, searchValue);
      }
    },
    [searchValue]
  );

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      swiper?.slideTo(0);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <IndeterminateLoader loading={loading} />
      <div className="max-w-[87.5vw] mx-auto pt-12">
        <img
          src={VenueVowLogo}
          alt="VenuVow"
          loading="lazy"
          className="cursor-pointer mb-1"
          onClick={() => navigate("/")}
        />
        <div
          className="flex mt-2.5 w-[87.5vw] absolute left-[6vw]"
          style={{ zIndex: 2 }}
        >
          <InputGroup
            inside
            style={{ width: "85%", maxWidth: 435 }}
            className="flex-row-reverse"
          >
            <Input
              className="expandable-textarea"
              placeholder="User’s search query..."
              value={searchValue ?? ""}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              ref={textareaRef}
              onFocus={handleHeight}
              onBlur={handleHeight}
              as="textarea"
            />
            <InputGroup.Button
              style={{
                backgroundColor: searchValue
                  ? "var(--rs-primary-300)"
                  : "var(--rs-gray-200)",
                height: "100%",
                borderRadius: "0 5px 5px 0",
              }}
              onClick={() => setTriggerSearch(!triggerSearch)}
            >
              <img
                className="w-[16px] h-[13px]"
                src={SearchIcon}
                alt="search"
                loading="lazy"
              />
              {/* <Search
                color="#9010A1"
                onClick={() => setTriggerSearch(!triggerSearch)}
              /> */}
            </InputGroup.Button>
          </InputGroup>
          {speech.browserSupportsSpeechRecognition ? (
            <>
              <div className="hidden sm:block">
                <IconButton
                  appearance="ghost"
                  size="md"
                  style={{
                    paddingInline: 12,
                    marginLeft: 8,
                    boxShadow: "none",
                    borderColor: "var(--rs-gray-400)",
                    position: "relative",
                    maxHeight: 36,
                    backgroundColor: speech.listening
                      ? "var(--rs-primary-100)"
                      : "transparent",
                  }}
                  onClick={() => {
                    speech.listening
                      ? speech.stopListening()
                      : speech.startListening();
                  }}
                >
                  {speech.listening ? (
                    <span className="w-5 h-5 pulse"></span>
                  ) : null}
                  <img src={VoiceIcon} alt="voice-icon" loading="lazy" />
                </IconButton>
              </div>
              <div className="block sm:hidden">
                <IconButton
                  appearance="ghost"
                  size="md"
                  style={{
                    paddingInline: 12,
                    marginLeft: 8,
                    boxShadow: "none",
                    borderColor: "var(--rs-gray-400)",
                    position: "relative",
                    maxHeight: 36,
                    backgroundColor: speech.listening
                      ? "var(--rs-primary-100)"
                      : "transparent",
                  }}
                  onTouchStart={() => {
                    speech.startListening();
                  }}
                  onTouchEnd={() => {
                    speech.stopListening();
                  }}
                >
                  {speech.listening ? (
                    <span className="w-5 h-5 pulse"></span>
                  ) : null}
                  <img src={VoiceIcon} alt="voice-icon" loading="lazy" />
                </IconButton>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Divider
        style={{
          backgroundColor: "var(--rs-gray-400)",
          marginBottom: 35,
          marginTop: 76,
        }}
      />
      <div className="max-w-[87.5vw] mx-auto">
        <h1 className="title-1 mb-7 hidden md:block">Results</h1>
        <h1 className="title-3 mb-3 block md:hidden">Results</h1>
        <FlexboxGrid className="mb-[22px]" style={{ alignItems: "stretch" }}>
          {!loading && [...venueImages].length === 0 ? (
            <h2 className="body-2">
              We could not find any venue with given description.
            </h2>
          ) : null}
          {[...venueImages]
            .splice(0, maxVenue)
            .map((images: any, index: number) => {
              return (
                <ResultCard
                  key={index}
                  data={venues[index]}
                  images={venueImages[index]}
                />
              );
            })}
          {loading
            ? [
                ...Array(Math.max(maxVenue - Object.keys(rendered).length, 0)),
              ].map((item, index) => <ResultCardSkeleton key={index} />)
            : null}
        </FlexboxGrid>
        <div className="flex justify-between mb-14 flex-col sm:flex-row">
          <Button
            appearance="primary"
            className="button-text "
            style={{
              padding: "7px 24px 5px 16px",
              height: "fit-content",
              visibility:
                Object.keys(loaded).length > maxVenue ? "visible" : "hidden",
            }}
            onClick={() => {
              setMaxVenue((data) => data + NUMBER_OF_RESULTS);
              handleLoadMore();
            }}
          >
            <img
              style={{ marginRight: 10 }}
              src={ReloadIcon}
              alt="reload-icon"
              loading="lazy"
            />
            LOAD MORE
          </Button>
          <div className="flex gap-2 mt-4 sm:mt-0 ">
            <Button
              appearance="primary"
              className="button-text button-outlined"
              style={{
                padding: "7px 24px 5px 24px",
                height: "fit-content",
              }}
              onClick={() => navigate("/")}
            >
              START A NEW SEARCH
            </Button>

            {searchValue && !loading ? (
              <>
                <Button
                  appearance={"primary"}
                  className={`${
                    liked === false
                      ? "button-outlined deactive"
                      : "button-outlined"
                  }`}
                  disabled={liked !== null}
                  style={{ padding: "8px" }}
                  onClick={() => handleLikeDislike(true)}
                >
                  <img src={LikeIcon} alt="like-icon" loading="lazy" />
                </Button>
                <Button
                  appearance={"primary"}
                  className={`${
                    liked === true
                      ? "button-outlined deactive"
                      : "button-outlined"
                  }`}
                  style={{ padding: "8px" }}
                  disabled={liked !== null}
                  onClick={() => handleLikeDislike(false)}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={LikeIcon}
                    alt="dislike-icon"
                    loading="lazy"
                  />
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <h2 className="title-1 mb-7 hidden md:block">
          You may also be interested in
        </h2>
        <h2 className="title-3 mb-3 block md:hidden">
          You may also be interested in
        </h2>
      </div>
      <div
        // className="block md:hidden"
        className="mb-4 relative"
      >
        {(relevantLength && loadingRelevant) || relevantVenueImages.length ? (
          <>
            <Swiper
              className="md:max-w-[87.5vw] mx-auto interested-in-swiper"
              spaceBetween={0}
              slidesPerView={1.3}
              loop
              centeredSlides={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              modules={[Navigation]}
              onSwiper={setSwiper}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                992: {
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                1280: {
                  slidesPerView: 4,
                  centeredSlides: false,
                },
              }}
            >
              {[...relevantVenueImages].map((item, index) => {
                return (
                  <SwiperSlide key={index} lazy={true}>
                    <ResultCard
                      data={relevantVenues[index]}
                      images={relevantVenueImages[index]}
                      noDescription={true}
                      fullSize={true}
                    />
                  </SwiperSlide>
                );
              })}
              {loadingRelevant
                ? [
                    ...Array(
                      relevantLength - relevantLoaded < 0
                        ? 0
                        : relevantLength - relevantLoaded
                    ),
                  ].map((item, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      <ResultCardSkeleton
                        key={index}
                        noDescription={true}
                        fullSize={true}
                      />
                    </SwiperSlide>
                  ))
                : null}
            </Swiper>

            <div
              className="interested-in-nav swiper-button-prev"
              ref={navigationPrevRef}
            ></div>
            <div
              className="interested-in-nav swiper-button-next"
              ref={navigationNextRef}
            ></div>
          </>
        ) : null}
      </div>
      <Footer />
    </>
  );
};

export default SearchResults;
