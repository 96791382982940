import { FrontPage } from "../pages/front-page";
import { SearchResults } from "../pages/search-results";
import { PrivacyPolicy } from "../pages/singles/privacy-policy";
import { TermsOfUse } from "../pages/singles/terms-of-use";
import { Venue } from "../pages/venue";

interface Props {
  name: string;
}

const Dummy = ({ name }: Props) => <p>{name}</p>;

export const routes = (isPing: boolean) => [
  {
    path: "/",
    element: <FrontPage />,
  },
  {
    path: "/results",
    element: <SearchResults />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "/venue/:slug",
    element: <Venue />,
  },

  {
    path: "*",
    element: <Dummy name="404" />,
  },
];
