import { API_URL, API_KEY } from "../config";
interface contactModel {
  name: string;
  email: string;
  description: string;
  venueId: string;
}
export const contactApi = async (payload: contactModel) => {
  const response = await fetch(`${API_URL}/contact/`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY ?? "",
    },
  });
  const images = response.json();
  return images;
};