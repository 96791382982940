import VenueVowLogo from "../../assets/images/venue-vow.svg";
import SearchIcon from "../../assets/images/search-icon.svg";
import VoiceIcon from "../../assets/images/voice.svg";
import { SampleQueries } from "./components";
import { IconButton, Input } from "rsuite";
import { Footer } from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useSpeechRecognition } from "../../hooks/speech-recognition";
import { MAX_QUERY_LENGTH } from "../../config";
const FrontPage = () => {
  const speech = useSpeechRecognition();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const handleHeight = (value: any, e: any) => {
    if (value.length > MAX_QUERY_LENGTH) return;
    setValue(value);
    const element = e.target;
    element.style.height = "5px";
    element.style.height = element.scrollHeight + 20 + "px";
  };

  const handleSearch = useCallback(() => {
    if (value !== "") {
      navigate(`/results?s=${value}`);
    }
  }, [value]);
  const handleKeyDown = function (event: any) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      const message = event.target.value.trim();
      if (message !== "") {
        navigate(`/results?s=${message}`);
      }
    }
  };
  useEffect(() => {
    if (speech.transcript.length <= MAX_QUERY_LENGTH) {
      setValue(speech.transcript);
    }
  }, [speech.transcript]);

  const [mobileListening, setMobileListening] = useState(false);
  return (
    <>
      <img
        className="absolute top-[28px] left-[7.5%] md:left-[6.25%]"
        src={VenueVowLogo}
        alt="Baroj Logo"
        loading="lazy"
      />
      <div
        className="bg-[#002430] min-h-[100vh] pt-[44px] sm:pt-[10vh] md:pt-[23vh] px-[7.6vw] md:px-[10vw] lg:px-[22.8vw] lg:pr-[27vw]"
        style={{
          backgroundImage: "url(./images/blake-griffin.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="flex mt-8 md:mt-4 min-h-[90px] px-3 py-[10px] bg-white rounded-[6px]"
          style={{
            alignItems: "stretch",
            border: "1px solid var(--rs-gray-400)",
          }}
        >
          {/* <div className="h-full pr-1.5 mt-[-1px]">
            <Search style={{ fontSize: 16 }} />
          </div> */}
          <Input
            rows={1}
            className="w-full h-full outline-none resize-none bg-none border-none p-0"
            style={{
              backgroundColor: "#fff",
              border: "none",
              resize: "none",
              padding: 0,
              borderRadius: 0,
              // paddingRight: 70,
            }}
            name=""
            as="textarea"
            value={value}
            onChange={handleHeight}
            onKeyDown={handleKeyDown}
            placeholder="Describe your dream venue..."
          />
          <div className="flex flex-col justify-between items-end">
            {speech.browserSupportsSpeechRecognition ? (
              <div className="h-full pl-1.5">
                <div
                  className={`flex sm:hidden items-center justify-center w-7 h-7 rounded-full relative cursor-pointer ${
                    mobileListening ? `bg-[--rs-primary-100]` : ""
                  }`}
                  onTouchStart={() => {
                    setMobileListening(true);
                    speech.startListening();
                  }}
                  onTouchEnd={() => {
                    setMobileListening(false);
                    speech.stopListening();
                  }}
                >
                  {mobileListening ? <span className="pulse"></span> : null}
                  <img src={VoiceIcon} alt="voice-icon" loading="lazy" />
                </div>
                {speech.listening ? (
                  <div
                    className="hidden sm:flex items-center justify-center bg-[--rs-primary-100] w-7 h-7 rounded-full relative cursor-pointer"
                    onClick={speech.stopListening}
                  >
                    <span className="pulse"></span>
                    <img src={VoiceIcon} alt="voice-icon" loading="lazy" />
                  </div>
                ) : (
                  <>
                    <div
                      className="hidden sm:flex items-center justify-center w-7 h-7 rounded-full cursor-pointer"
                      onClick={speech.startListening}
                    >
                      <img src={VoiceIcon} alt="voice-icon" loading="lazy" />
                    </div>
                  </>
                )}
              </div>
            ) : null}
            <div className="flex items-end relative">
              <span
                style={{
                  color:
                    value.length <= MAX_QUERY_LENGTH
                      ? "var(--rs-gray-500)"
                      : "var(--rs-error-500)",
                  position: "absolute",
                  right: 40,
                  bottom: 0,
                }}
              >
                {value.length}/{MAX_QUERY_LENGTH}
              </span>
              <IconButton
                appearance="ghost"
                size="md"
                style={{
                  padding: "10px 2px 10px 4px",
                  backgroundColor: value
                    ? "var(--rs-primary-300)"
                    : "var(--rs-gray-200)",
                  boxShadow: "none",
                  border: "none",
                  marginLeft: 6,
                  width: 30,
                  height: 30,
                }}
                onClick={handleSearch}
              >
                <img
                  className="w-[16px] h-[13px]"
                  src={SearchIcon}
                  alt="search"
                  loading="lazy"
                />
              </IconButton>
            </div>
          </div>
        </div>
        <SampleQueries />
      </div>
      <Footer />
    </>
  );
};

export default FrontPage;
