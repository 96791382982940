import VenueVowLogo from "../../assets/images/venue-vow2.svg";

import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/footer/footer";

export const TermsOfUse = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="max-w-[87.5vw] mx-auto pt-12 mb-12">
        <img
          src={VenueVowLogo}
          alt="VenuVow"
          loading="lazy"
          className="cursor-pointer"
          onClick={() => navigate("/")}
        />

        <h1 className="text-center title--5 mt-5">Terms of Use</h1>
        <p className="title-1 mt-5">Last Updated: 10/27/2023</p>

        <h2 className="title-1 mt-5">1. Acceptance of Terms</h2>
        <p className="mt-3">
          By accessing or using the Baroj Events website or any of its services,
          you agree to comply with and be bound by these Terms of Service
          ("Terms"). If you do not agree with any part of these Terms, please
          refrain from using our services.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">2. Description of Service</h2>
        <p className="mt-3">
          Baroj Events provides a platform where users can search and learn
          about various wedding venues based on their preferences.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">3. User Responsibilities</h2>
        <p className="mt-2">
          Users agree to use our services for lawful purposes only and in a
          manner that does not infringe upon the rights of, or restrict or
          inhibit the use and enjoyment of this site by any third party. Users
          are solely responsible for any decisions made based on the information
          or search results provided by Baroj Events.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">4. Content and Accuracy</h2>
        <p className="mt-2">
          While Baroj Events endeavors to provide accurate and up-to-date venue
          information, we do not guarantee or warrant the accuracy,
          completeness, or reliability of any content. Venue descriptions are
          promotional and generated by AI systems. These are intended for
          informational purposes and not as definitive accounts of the venues.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">5. Intellectual Property</h2>

        <p className="mt-2">
          All content on the Baroj Events website, excluding linked images and
          AI-generated descriptions, is the intellectual property of Baroj
          Events and is protected by applicable copyright and trademark laws.
          Unauthorized use or distribution of our content without written
          consent is strictly prohibited.
        </p>

        <h2 className="title-1 mt-5">6. Third-Party Links and Content</h2>

        <p className="mt-2">
          Baroj Events may provide links to external websites or resources.
          These links are provided for convenience and do not signify
          endorsement of the external sites. We are not responsible for the
          content or availability of these external sites. All venue images are
          linked to publicly accessible content, and Baroj Events does not claim
          ownership or copyright of these images.
        </p>

        <h2 className="title-1 mt-5">7. Limitation of Liability</h2>

        <p className="mt-2">
          Baroj Events shall not be held liable for any direct, indirect,
          incidental, or consequential damages or losses arising from:
        </p>

        <p className="mt-2">
          The use or inability to use our services. Any decisions made based on
          the information provided by our platform. Unauthorized access to or
          alterations of your transmissions or data.
        </p>

        <h2 className="title-1 mt-5">8. Changes to the Terms of Service</h2>

        <p className="mt-2">
          We reserve the right to modify or replace these Terms at any time.
          When changes are made, the "Last Updated" date at the top will be
          revised. It is the responsibility of the user to regularly review the
          Terms to stay informed.
        </p>

        <h2 className="title-1 mt-5">9. Governing Law</h2>

        <p className="mt-2">
          These Terms are governed by and construed in accordance with United
          States of America and State of Illinois Law, and any disputes arising
          from or relating to these Terms will be subject to the exclusive
          jurisdiction of the courts of Illinois.
        </p>

        <h2 className="title-1 mt-5">10. Contact</h2>

        <p className="mt-2">
          For any queries regarding these Terms of Service, please contact us at
          hello@barojevents.com.
        </p>
      </div>
      <Footer />
    </>
  );
};
