import { ImageGrid4 } from "./layouts/image-grid-4";
import { ImageGrid3 } from "./layouts/image-grid-3";
import { ImageGrid2 } from "./layouts/image-grid-2";
import { ImageGrid1 } from "./layouts/image-grid-1";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { HEIGHT_RATO } from "../../../config";
import { getPrimaryColor } from "../../../utils/helpers";

interface propsInterface {
  images: {
    ImageID: number;
    ImageURL: string;
    VenueID: number;
    PrimaryColors: string;
  }[];
}

const convertArrayToSubarrayWithLengthN = (initArray: any[], n: number) => {
  let arrays = [];
  let array = [...initArray];
  while (array.length > 0) arrays.push(array.splice(0, n));
  return arrays;
};
const ImageSelection = ({ images }: propsInterface) => {
  return (
    <>
      {images.length === 1 ? (
        <ImageGrid1 images={images} />
      ) : images.length === 2 ? (
        <ImageGrid2 images={images} />
      ) : images.length === 3 ? (
        <ImageGrid3 images={images} />
      ) : images.length === 4 ? (
        <ImageGrid4 images={images} />
      ) : (
        <ImageGrid1 images={[]} />
      )}
    </>
  );
};
export const VenueImages = ({ images }: propsInterface) => {
  return (
    <>
      <div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop
          navigation={true}
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          className="venue-details-swiper venue-details-swiper-mobile"
        >
          {images.map((imageChunk: any, index: number) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  backgroundColor: `${getPrimaryColor(
                    imageChunk.PrimaryColors
                  )}`,
                  cursor: "pointer",
                  aspectRatio: 474 / 394,
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    aspectRatio: 474 / 394,
                    width: "100%",
                    maxHeight: "100%",
                    border: "2px solid #fff",
                  }}
                  src={imageChunk.ImageURL}
                  alt=""
                  loading="lazy"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
