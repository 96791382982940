import { FC, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { routes } from "../../router";

const RouteHandler: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const routing = useRoutes(routes(!!loading));

  if (loading) return <div className="loading-table" />;

  return routing;
};

export default RouteHandler;
