import VenueVowLogo from "../../assets/images/venue-vow2.svg";

import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/footer/footer";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="max-w-[87.5vw] mx-auto pt-12 mb-12">
        <img
          src={VenueVowLogo}
          alt="VenuVow"
          loading="lazy"
          className="cursor-pointer"
          onClick={() => navigate("/")}
        />

        <h1 className="text-center title--5 mt-5">VenueVou Privacy Policy </h1>
        <p className="title-1 mt-5">Last Updated: 10/27/2023</p>

        <h2 className="title-1 mt-5">1. Introduction</h2>
        <p className="mt-3">
          Welcome to Baroj Events. We recognize the importance of privacy and
          are committed to protecting the personal information of our users.
          This Privacy Policy outlines how we collect, use, and safeguard your
          information when you use our service.
        </p>
        <p className="mt-2">
          By accessing or using our website, you signify your acceptance of this
          Privacy Policy. If you disagree with any part of this policy, please
          refrain from using our service.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">2. Information We Collect</h2>
        <p className="mt-3">
          <strong>Search Queries:</strong> When you use our website to describe
          your dream venue, we record your query to help improve the accuracy
          and relevance of our search results.
        </p>
        <p className="mt-2">
          <strong>IP Addresses:</strong> For operational and maintenance
          purposes, we log the IP addresses of users. IP addresses can be
          considered as non-personally identifiable data, as they do not
          directly reveal the identity of an individual on their own.
        </p>
        <p className="mt-2">
          <strong>Email Addresses:</strong> If you express interest in learning
          more about a venue and choose to leave your email address, we collect
          this information to facilitate communication between Baroj Events and
          you.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">3. How We Use Your Information</h2>
        <p className="mt-2">
          Operational Purposes: Your IP address helps us diagnose server
          problems, administer our website, and ensure the smooth functioning of
          our services.
        </p>
        <p className="mt-2">
          Enhancing User Experience: By tracking search queries, we strive to
          refine our database and improve the relevance and accuracy of our
          venue recommendations.
        </p>
        <p className="mt-2">
          Communication: If you leave your email address, we use it exclusively
          to provide information on the venues you've shown interest in.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">4. Data Security</h2>
        <p className="mt-2">
          We prioritize the security of your information. We adopt robust
          technical measures to safeguard our database from unauthorized access,
          alteration, or disclosure. However, no online transmission or
          electronic storage is 100% secure; therefore, while we strive to
          protect your personal data, we cannot guarantee its absolute security.
        </p>

        {/*  */}
        <h2 className="title-1 mt-5">5. Data Retention</h2>

        <p className="mt-2">
          Your search queries and IP addresses are retained for a limited period
          to aid in operational purposes. Email addresses provided by users
          interested in venues are stored as long as necessary to fulfill the
          purpose of communication or until the user requests its deletion.{" "}
        </p>

        <h2 className="title-1 mt-5">6. Third-Party Websites</h2>

        <p className="mt-2">
          Our website may contain links to other sites. This Privacy Policy does
          not apply to those sites, and we recommend reviewing their respective
          privacy policies before providing any personal data.
        </p>

        <h2 className="title-1 mt-5">7. Wedding Venue Information</h2>

        <p className="mt-2">
          Source of Venue Data: The details about the wedding venues provided on
          Baroj Events are the result of extensive research conducted by Baroj
          Events and its representatives.
        </p>

        <p className="mt-2">
          Promotional Descriptions: Descriptions of the venues are promotional
          in nature, designed to provide a snapshot of what the venue offers.
          These descriptions are generated by advanced AI systems, such as
          ChatGPT, and are meant for informational purposes rather than being a
          precise depiction of the venue.
        </p>

        <p className="mt-2">
          Image Storage and Links: Baroj Events does not store any images
          directly on our servers. All images associated with the wedding venues
          are links to publicly accessible images. We do not claim ownership or
          copyrights of these images.
        </p>

        <p className="mt-2">
          Accuracy of Venue Details and Search Results: While we strive to
          provide the most accurate and up-to-date information about the wedding
          venues, we do not guarantee the accuracy, completeness, or reliability
          of the details provided. Similarly, the accuracy of search results,
          while continually refined, may not always meet user expectations or
          criteria perfectly. Users are encouraged to conduct their independent
          research or visit the venues to verify the details before making any
          decisions. Baroj Events shall not be held liable for any discrepancies
          in venue information or any decisions made based on the search
          results.
        </p>

        <h2 className="title-1 mt-5">8. Changes to This Privacy Policy</h2>

        <p className="mt-2">
          Baroj Events may update this Privacy Policy occasionally. When we do,
          the "Last Updated" date at the top will be revised. We encourage users
          to regularly check this page for any changes and stay informed about
          how we protect the collected personal data.
        </p>

        <h2 className="title-1 mt-5">9. Contact Us</h2>

        <p className="mt-2">
          If you have questions or concerns about this Privacy Policy or our
          practices, please reach out to us at hello@barojevents.com.
        </p>
      </div>
      <Footer />
    </>
  );
};
